import { ISelectOptions, ITimeOptionType } from './models';
import {
  TCannabinoidUnit,
  TDoctorStatus,
  TDvaColors,
  TGenericName,
  TLimits,
  TPatientStatus,
  TProductForm,
  TProductStatus,
  TProductType,
  TUserTypes
} from './types';

export const TIMEZONES: ISelectOptions[] = [
  {
    value: 'Australia/Eucla',
    label: '(GMT+8:45) Eucla'
  },
  {
    value: 'Australia/Perth',
    label: '(GMT+8:00) AWST - Perth'
  },
  {
    value: 'Australia/Darwin',
    label: '(GMT+9:30) ACST - Darwin'
  },
  {
    value: 'Australia/Brisbane',
    label: '(GMT+10:00) AEST - Brisbane'
  },
  {
    value: 'Australia/Lindeman',
    label: '(GMT+10:00) AEST - Lindeman'
  },
  {
    value: 'Australia/Adelaide',
    label: '(GMT+10:30) ACDT - Adelaide'
  },
  {
    value: 'Australia/Broken_Hill',
    label: '(GMT+10:30) ACDT - Broken Hill'
  },
  {
    value: 'Australia/ACT',
    label: '(GMT+11:00) AEST - ACT'
  },
  {
    value: 'Australia/Hobart',
    label: '(GMT+11:00) AEDT - Hobart'
  },
  {
    value: 'Australia/Melbourne',
    label: '(GMT+11:00) AEDT - Melbourne'
  },
  {
    value: 'Australia/Sydney',
    label: '(GMT+11:00) AEDT - Sydney'
  }
];

export const GENDER_OPTIONS: ISelectOptions[] = [
  {
    value: 'MALE',
    label: 'Male'
  },
  {
    value: 'FEMALE',
    label: 'Female'
  },
  {
    value: 'NOTTOSAY',
    label: 'Preferred not to say'
  },
  {
    value: 'OTHERS',
    label: 'Other'
  }
];

export const CONDITIONS: string[] = [
  'Chronic Pain',
  'Anxiety',
  'Depression',
  'Chronic Insomnia',
  'Migraine',
  'Neuropathic Pain',
  'Multiple Sclerosis',
  'PTSD',
  'Cancer Pain',
  'Palliative Care',
  'Epilepsy',
  'Chronic Illness',
  'Chemotherapy Induced Nausea & Vomiting'
];

export const TIME_OPTIONS: ITimeOptionType[] = [
  {
    label: '12:00 AM',
    value: '00:00'
  },
  {
    label: '12:30 AM',
    value: '00:30'
  },
  {
    label: '01:00 AM',
    value: '01:00'
  },
  {
    label: '01:30 AM',
    value: '01:30'
  },
  {
    label: '02:00 AM',
    value: '02:00'
  },
  {
    label: '02:30 AM',
    value: '02:30'
  },
  {
    label: '03:00 AM',
    value: '03:00'
  },
  {
    label: '03:30 AM',
    value: '03:30'
  },
  {
    label: '04:00 AM',
    value: '04:00'
  },
  {
    label: '04:30 AM',
    value: '04:30'
  },
  {
    label: '05:00 AM',
    value: '05:00'
  },
  {
    label: '05:30 AM',
    value: '05:30'
  },
  {
    label: '06:00 AM',
    value: '06:00'
  },
  {
    label: '06:30 AM',
    value: '06:30'
  },
  {
    label: '07:00 AM',
    value: '07:00'
  },
  {
    label: '07:30 AM',
    value: '07:30'
  },
  {
    label: '08:00 AM',
    value: '08:00'
  },
  {
    label: '08:30 AM',
    value: '08:30'
  },
  {
    label: '09:00 AM',
    value: '09:00'
  },
  {
    label: '09:30 AM',
    value: '09:30'
  },
  {
    label: '10:00 AM',
    value: '10:00'
  },
  {
    label: '10:30 AM',
    value: '10:30'
  },
  {
    label: '11:00 AM',
    value: '11:00'
  },
  {
    label: '11:30 AM',
    value: '11:30'
  },
  {
    label: '12:00 PM',
    value: '12:00'
  },
  {
    label: '12:30 PM',
    value: '12:30'
  },
  {
    label: '01:00 PM',
    value: '13:00'
  },
  {
    label: '01:30 PM',
    value: '13:30'
  },
  {
    label: '02:00 PM',
    value: '14:00'
  },
  {
    label: '02:30 PM',
    value: '14:30'
  },
  {
    label: '03:00 PM',
    value: '15:00'
  },
  {
    label: '03:30 PM',
    value: '15:30'
  },
  {
    label: '04:00 PM',
    value: '16:00'
  },
  {
    label: '04:30 PM',
    value: '16:30'
  },
  {
    label: '05:00 PM',
    value: '17:00'
  },
  {
    label: '05:30 PM',
    value: '17:30'
  },
  {
    label: '06:00 PM',
    value: '18:00'
  },
  {
    label: '06:30 PM',
    value: '18:30'
  },
  {
    label: '07:00 PM',
    value: '19:00'
  },
  {
    label: '07:30 PM',
    value: '19:30'
  },
  {
    label: '08:00 PM',
    value: '20:00'
  },
  {
    label: '08:30 PM',
    value: '20:30'
  },
  {
    label: '09:00 PM',
    value: '21:00'
  },
  {
    label: '09:30 PM',
    value: '21:30'
  },
  {
    label: '10:00 PM',
    value: '22:00'
  },
  {
    label: '10:30 PM',
    value: '22:30'
  },
  {
    label: '11:00 PM',
    value: '23:00'
  },
  {
    label: '11:30 PM',
    value: '23:30'
  }
];

export const TIME_OPTIONS_15: ITimeOptionType[] = [
  {
    label: '07:00 AM',
    value: '07:00'
  },
  {
    label: '07:15 AM',
    value: '07:15'
  },
  {
    label: '07:30 AM',
    value: '07:30'
  },
  {
    label: '07:45 AM',
    value: '07:45'
  },
  {
    label: '08:00 AM',
    value: '08:00'
  },
  {
    label: '08:15 AM',
    value: '08:15'
  },
  {
    label: '08:30 AM',
    value: '08:30'
  },
  {
    label: '08:45 AM',
    value: '08:45'
  },
  {
    label: '09:00 AM',
    value: '09:00'
  },
  {
    label: '09:15 AM',
    value: '09:15'
  },
  {
    label: '09:30 AM',
    value: '09:30'
  },
  {
    label: '09:45 AM',
    value: '09:45'
  },
  {
    label: '10:00 AM',
    value: '10:00'
  },
  {
    label: '10:15 AM',
    value: '10:15'
  },
  {
    label: '10:30 AM',
    value: '10:30'
  },
  {
    label: '10:45 AM',
    value: '10:45'
  },
  {
    label: '11:00 AM',
    value: '11:00'
  },
  {
    label: '11:15 AM',
    value: '11:15'
  },
  {
    label: '11:30 AM',
    value: '11:30'
  },
  {
    label: '11:45 AM',
    value: '11:45'
  },
  {
    label: '12:00 PM',
    value: '12:00'
  },
  {
    label: '12:15 PM',
    value: '12:15'
  },
  {
    label: '12:30 PM',
    value: '12:30'
  },
  {
    label: '12:45 PM',
    value: '12:45'
  },
  {
    label: '01:00 PM',
    value: '13:00'
  },
  {
    label: '01:15 PM',
    value: '13:15'
  },
  {
    label: '01:30 PM',
    value: '13:30'
  },
  {
    label: '01:45 PM',
    value: '13:45'
  },
  {
    label: '02:00 PM',
    value: '14:00'
  },
  {
    label: '02:15 PM',
    value: '14:15'
  },
  {
    label: '02:30 PM',
    value: '14:30'
  },
  {
    label: '02:45 PM',
    value: '14:45'
  },
  {
    label: '03:00 PM',
    value: '15:00'
  },
  {
    label: '03:15 PM',
    value: '15:15'
  },
  {
    label: '03:30 PM',
    value: '15:30'
  },
  {
    label: '03:45 PM',
    value: '15:45'
  },
  {
    label: '04:00 PM',
    value: '16:00'
  },
  {
    label: '04:15 PM',
    value: '16:15'
  },
  {
    label: '04:30 PM',
    value: '16:30'
  },
  {
    label: '04:45 PM',
    value: '16:45'
  },
  {
    label: '05:00 PM',
    value: '17:00'
  },
  {
    label: '05:15 PM',
    value: '17:15'
  },
  {
    label: '05:30 PM',
    value: '17:30'
  },
  {
    label: '05:45 PM',
    value: '17:45'
  },
  {
    label: '06:00 PM',
    value: '18:00'
  },
  {
    label: '06:15 PM',
    value: '18:15'
  },
  {
    label: '06:30 PM',
    value: '18:30'
  },
  {
    label: '06:45 PM',
    value: '18:45'
  },
  {
    label: '07:00 PM',
    value: '19:00'
  },
  {
    label: '07:30 PM',
    value: '19:30'
  },
  {
    label: '07:45 PM',
    value: '19:45'
  },
  {
    label: '08:00 PM',
    value: '20:00'
  },
  {
    label: '08:15 PM',
    value: '20:15'
  },
  {
    label: '08:30 PM',
    value: '20:30'
  },
  {
    label: '08:45 PM',
    value: '20:45'
  },
  {
    label: '09:00 PM',
    value: '21:00'
  },
  {
    label: '09:15 PM',
    value: '21:15'
  },
  {
    label: '09:30 PM',
    value: '21:30'
  },
  {
    label: '09:45 PM',
    value: '21:45'
  },
  {
    label: '10:00 PM',
    value: '22:00'
  },
  {
    label: '10:15 PM',
    value: '22:15'
  },
  {
    label: '10:30 PM',
    value: '22:30'
  },
  {
    label: '10:45 PM',
    value: '22:45'
  },
  {
    label: '11:00 PM',
    value: '23:00'
  }
];

export const TABLE_PER_PAGE: ISelectOptions[] = [
  {
    label: '5 per page',
    value: 5
  },
  {
    label: '10 per page',
    value: 10
  },
  {
    label: '20 per page',
    value: 20
  },
  {
    label: '25 per page',
    value: 25
  },
  {
    label: '50 per page',
    value: 50
  },
  {
    label: '100 per page',
    value: 100
  }
];

export const PRESCRIPTION_STATUS_TOKEN = {
  PENDING: {
    tokenBg: 'bg-red'
  },
  ACTIVE: {
    tokenBg: 'bg-green'
  },
  INVOICED: {
    tokenBg: 'bg-green'
  },
  PAID: {
    tokenBg: 'bg-green'
  },
  READY_FOR_DISPENSING: {
    tokenBg: 'bg-green'
  },
  OVERDUE: {
    tokenBg: 'bg-red'
  },
  DISPENSING: {
    tokenBg: 'bg-yellow'
  },
  DISPENSED: {
    tokenBg: 'bg-blue'
  },
  SHIPPING: {
    tokenBg: 'bg-yellow'
  },
  DELIVERED: {
    tokenBg: 'bg-green'
  },
  INACTIVE: {
    tokenBg: 'bg-red'
  },
  EXPIRED: {
    tokenBg: 'bg-red'
  },
  EXPORTED: {
    tokenBg: 'bg-blue'
  },
  TRANSFERRED: {
    tokenBg: 'bg-blue'
  }
};

export const DEFAULT_TIMEZONE = 'Australia/Adelaide';

export const CONSULTATION_STATUS: ISelectOptions[] = [
  {
    label: 'Precall',
    value: 'PRECALL'
  },
  {
    label: 'On call',
    value: 'ONCALL'
  },
  {
    label: 'Call end',
    value: 'CALLEND'
  },
  {
    label: 'Postcall',
    value: 'POSTCALL'
  },
  {
    label: 'Cancel',
    value: 'CANCEL'
  },
  {
    label: 'Completed',
    value: 'DONE'
  },
  {
    label: 'Did not attend',
    value: 'DIDNOTATTEND'
  }
];

export const CONSULTATION_TYPE = [
  'Initial Consultation',
  'Standard Consultation',
  'Short Consultation'
];

export const STATE_OPTIONS: ISelectOptions[] = [
  { label: 'ACT', value: 'ACT' },
  { label: 'NSW', value: 'NSW' },
  { label: 'NT', value: 'NT' },
  { label: 'QLD', value: 'QLD' },
  { label: 'SA', value: 'SA' },
  { label: 'TAS', value: 'TAS' },
  { label: 'VIC', value: 'VIC' },
  { label: 'WA', value: 'WA' }
];

export const CONSULTATION_STATUS_CX = {
  PENDING: {
    name: 'Pending',
    className: 'text-red-500'
  },
  PRECALL: {
    name: 'Precall',
    className: 'text-yellow-500'
  },
  POSTCALL: {
    name: 'Postcall',
    className: 'text-blue-500'
  },
  CALLEND: {
    name: 'Call ended',
    className: 'text-red-500'
  },
  DONE: {
    name: 'Completed',
    className: 'text-green-500'
  },
  DIDNOTATTEND: {
    name: 'Did Not Attend',
    className: 'text-red-500'
  },
  CANCEL: {
    name: 'Cancelled',
    className: 'text-red-500'
  },
  BLOCK: {
    name: 'Blockec',
    className: 'text-red-500'
  },
  DELETED: {
    name: 'Deleted',
    className: 'text-red-500'
  },
  UNPAID: {
    name: 'Unpaid',
    className: 'text-yellow-500'
  },
  ONCALL: {
    name: 'On call',
    className: 'text-green-500'
  }
};

export const ESCRIPT_STATUS = [
  'PENDING',
  'ACTIVE',
  'INVOICED',
  'PAID',
  'READY_FOR_DISPENSING',
  'DISPENSING',
  'DISPENSED',
  'SHIPPING',
  'DELIVERED',
  'CANCELLED',
  'INACTIVE'
];

export const SCRIPT_STATUS = [
  'PENDING',
  'ACTIVE',
  'READY_FOR_DISPENSING',
  'COMPLETED',
  'INACTIVE',
  'EXPIRED',
  'EXPORTED',
  'TRANSFERRED'
];

export const ESCRIPT_TOKEN_STATUS = [
  'PENDING',
  'ACTIVE',
  'INVOICED',
  'PAID',
  'READY_FOR_DISPENSING',
  'OVERDUE',
  'DISPENSING',
  'DISPENSED',
  'SHIPPING',
  'DELIVERED',
  'INACTIVE',
  'EXPIRED',
  'EXPORTED',
  'TRANSFERRED'
];

export const IS_DEV = ['development', 'qa', 'staging'];

export const USER_TYPES: TUserTypes[] = [
  'patient_access',
  'doctor_access',
  'consultation_access',
  'product_access',
  'prescription_access',
  'coupon_access',
  'reset_consultation_access',
  'generate_prescription_access',
  'reschedule_access',
  'did_not_attend_access',
  'cancel_access',
  'consultation_logs_access',
  'login_patient_access',
  'login_doctor_access',
  'update_wallet_access',
  'dispensing_limit_access',
  'dispensing_interval_access',
  'prescription_nova_access',
  'prescription_air_access',
  'view_clinical_notes_access'
];

export const PATIENT_STATUS_OPTIONS: TPatientStatus[] = [
  'ACTIVE',
  'DORMANT',
  'DISCHARGED',
  'BOOKED',
  'PENDING',
  'INACTIVE'
];

export const DOCTOR_STATUS_OPTIONS: TDoctorStatus[] = ['ACTIVE', 'INACTIVE'];

export const COMMUNICATION_PREFERENCE_OPTIONS: string[] = ['AUDIO', 'VIDEO', 'PHONE'];

export const ORDER_STATUS_PROCESSING = [
  'PENDING',
  'ACTIVE',
  'INVOICED',
  'PAID',
  'READY_FOR_DISPENSING',
  'DISPENSING',
  'DELAYED'
];

export const ORDER_STATUS_REVIEWING = ['REVIEW'];

export const ORDER_STATUS_SHIPPED = ['DISPENSED', 'SHIPPING'];

export const ORDER_STATUS_COMPLETED = ['DELIVERED', 'COMPLETED'];

export const ORDER_STATUS_HOLD = ['INACTIVE', 'ON_HOLD'];

export const ORDER_STATUS_FAILED = ['PAYMENT_FAILED'];

export const ORDER_STATUS_CANCELLED = ['CANCELLED'];

export const SHIPPING_FEE = 15;

export const REFUND_REASONS = [
  'Product Discontinued',
  'Product not avail',
  'Faulty Product',
  'Expired/Short Expiry Product'
];

export const ORDER_STATUS = [
  'PENDING',
  'ACTIVE',
  'INVOICED',
  'PAID',
  'READY_FOR_DISPENSING',
  'DISPENSING',
  'DISPENSED',
  'SHIPPING',
  'DELIVERED',
  'DELAYED',
  'CANCELLED',
  'INACTIVE',
  'PAYMENT_FAILED',
  'ON_HOLD'
];

export const DVA_COLORS_OPTIONS: (TDvaColors | null)[] = [null, 'G', 'O', 'W'];

export const PRODUCT_TYPES: TProductType[] = [
  'Accessories & Devices',
  'Medicinal Cannabis',
  'Pharmaceuticals',
  'Supplements'
];

export const GENERIC_NAME: TGenericName[] = ['Tetrahydrocannabinol', 'Cannabidiol'];

export const PRODUCT_BRANDS = [
  'Adaya',
  'Alfie Therapeutics',
  'Alma',
  'Althea',
  'Ananda Hemp',
  'ANTG',
  'Aura Therapeutics',
  'Aurora',
  'Beacon Medical',
  'BioCann',
  'Blue Mountain Global',
  'Bl√ºm',
  'BOB',
  'Botanitech',
  'Broken Coast',
  'Cannatrek',
  'Cannim',
  'CanniMed',
  'Canntic',
  'Carina Day & Night',
  'Chemovar',
  'CherryCo',
  'Circle',
  'Cornerfield',
  'Craft Botanics',
  'cultiva',
  'Cymra',
  'Delta Tetra',
  'El Camino',
  'Elevated Extracts',
  'Endoca',
  'Entoura',
  'Global Selects',
  'Grandiosa',
  'Hana Wellness',
  'Herb',
  'Heyday',
  'HighCountry',
  'Humacology',
  'IndiMed',
  'Isospec',
  'iX Biopharmac',
  'Ix Syrinx',
  'Kind Medical',
  'Levin Health',
  'Little Green Pharma',
  'Lumir',
  'Lyphe Australia',
  'Maali',
  'MCA',
  'Medcan Australia',
  'Medibis',
  'Mediquest',
  'Medlab',
  'MedReleaf',
  'Motherplant',
  'Palmitoylethanolamide',
  'Pharmacann',
  'Phytoca',
  'Potts Point',
  'Promethean Biopharma',
  'satipharm',
  'SatiVite',
  'Seremind',
  'Single Estate',
  'SOL',
  'Spectrum Therapeutics',
  'Sundaze',
  'Superbly Green',
  'Tasmanian Botanics',
  'Temple',
  'Tikun',
  'Tilray',
  'Upstate',
  'UrbanLeaf',
  'WafeRest',
  'Wild Flower'
];

export const PRODUCT_SPECIES = ['Hybrid', 'Indica', 'Indica Dominant', 'Sativa', 'Sativa Dominant'];

export const SIZE_QUANTITY = ['g', 'gummies', 'mg', 'mL', 'units'];

export const PRODUCT_STATUS: TProductStatus[] = ['ACTIVE', 'INACTIVE', 'DORMANT'];

export const PRODUCT_FORM: TProductForm[] = [
  'Capsules',
  'Edibles',
  'Extracts',
  'Flower',
  'Hash',
  'Inhaler',
  'Oil',
  'Patches',
  'Pessaries',
  'Spray',
  'Suppositories',
  'Topical',
  'Vapes'
];

export const DEFAULT_ROA = [
  'Inhalation via Vaporiser',
  'Inhalation',
  'Sublingual',
  'Oral',
  'Topical',
  'Transdermal',
  'Buccal Spray'
];

export const TERPENES_PROFILE = [
  '3-Carene',
  'Alpha Bisabolol',
  'Alpha Bulnesene',
  'Alpha Cedrene',
  'Alpha Gurjunene',
  'Alpha Humulene',
  'Alpha Pinene',
  'Alpha Terpinene',
  'Alpha Terpineol',
  'Alpha-Phellandrene',
  'Aristolene',
  'Beta Caryophyllene',
  'Beta Farnesene',
  'Beta Maaliene',
  'Beta Myrcene',
  'Beta Pinene',
  'Beta Selinene',
  'Beta-Eudesmol',
  'Borneol',
  'Cadinene',
  'Calarene',
  'Camphene',
  'Camphor',
  'Caryophyllene',
  'Caryophyllene oxide',
  'Cedrol',
  'cis-Nerolidol',
  'd-Endo-Fenchyl',
  'D-Limonene',
  'Delta Guaiene',
  'Delta-3-Carene',
  'E-beta-Ocimene',
  'Eucalyptol',
  'Farnesene',
  'Fenchol',
  'Geraniol',
  'Geranyl Acetate',
  'Germacrene B',
  'Guaia-6,9-diene',
  'Guaiol',
  'Humulene',
  'Isopulegol',
  'Limonene',
  'Linalool',
  'Myrcene',
  'Nerolidol',
  'Ocimene',
  'Phytol',
  'Pinene',
  'R-Limonene',
  'Sabinene',
  'Selina-3,7(11)-diene',
  'Selina-4(15),7(11)-diene',
  'Terpineol',
  'Terpinolene',
  'Trans Caryophyllene',
  'trans-Nerolidol',
  'Valencene',
  'Z-beta-Ocimene'
];

export const OTHER_CANNABINOIDS = ['CBC', 'CBG', 'CBN'];

export const PRODUCT_AVAILABILITY = [
  {
    label: 'In Stock',
    value: 'INSTOCK'
  },
  {
    label: 'Out of Stock',
    value: 'OUTOFSTOCK'
  },
  {
    label: 'Low Stock',
    value: 'LOWSTOCK'
  },
  {
    label: 'Discontinued',
    value: 'DISCONTINUED'
  },
  {
    label: 'Coming Soon',
    value: 'COMINGSOON'
  }
];

export const ESTIMATED_DELIVERY = ['1-3 Business Days', '2-5 Business Days'];

export const PRODUCT_LIMITS: TLimits[] = [
  'ORAL',
  'EDIBLES',
  'EXTRACTS',
  'FLOWER',
  'HASH',
  'INHALER',
  'OIL',
  'PATCHES',
  'PESSARY',
  'RESIN',
  'SPRAY',
  'TOPICAL',
  'VAPE'
];

export const PRODUCT_CANNABINOID: TCannabinoidUnit[] = ['CBC', 'CBG', 'CBN'];

export const PRODUCT_COMPOSITION = ['Compounded', 'Finished Product'];

export const PRODUCT_SCHEDULE = ['S2', 'S3', 'S4', 'S8', 'NA'];

export const MAX_TAGS = 20;
