import React, { FC, Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import storage from 'utils/storage';
import useAuth from 'hooks/useAuth';

// components
import Loadable from 'components/Loadable';
import LoaderSpinner from 'components/LoaderSpinner';

// layouts
import AuthLayout from 'layout/AuthLayout';
import ScrollToTop from 'components/ScrollToTop';
import Modal from 'components/Modal';
import ModalMessage from 'components/modals/ModalMessage';

// pages with loadable component
const Login = Loadable(lazy(() => import('pages/guest/Login')));
const Patients = Loadable(lazy(() => import('pages/authenticated/Patients')));
// const PatientProfile = Loadable(lazy(() => import('pages/authenticated/PatientProfile')));
const Doctors = Loadable(lazy(() => import('pages/authenticated/Doctors')));
const Consultations = Loadable(lazy(() => import('pages/authenticated/Consultations')));
const AdminUsers = Loadable(lazy(() => import('pages/authenticated/AdminUsers')));
const AdminUserTypes = Loadable(lazy(() => import('pages/authenticated/AdminUserTypes')));
const PaymentTransactions = Loadable(lazy(() => import('pages/authenticated/PaymentTransactions')));
const Products = Loadable(lazy(() => import('pages/authenticated/Products')));
const Orders = Loadable(lazy(() => import('pages/authenticated/Orders')));
const Prescriptions = Loadable(lazy(() => import('pages/authenticated/Prescriptions')));
const Coupons = Loadable(lazy(() => import('pages/authenticated/Coupons')));
const ProductManagement = Loadable(lazy(() => import('pages/authenticated/ProductManagement')));
const StripeCheckoutSuccess = Loadable(
  lazy(() => import('pages/authenticated/StripeCheckoutSuccess'))
);
const StripeCheckoutCancel = Loadable(
  lazy(() => import('pages/authenticated/StripeCheckoutCancel'))
);

const Router: FC = () => {
  const { isLoading, user, logout } = useAuth();
  const isLoggedIn = storage.getItem('token');

  const [open, setOpen] = useState<boolean>(false);

  let DefaultElement = user?.is_super_admin ? Patients : '';
  let defaultRouteString = user?.is_super_admin ? '/patients' : '';

  if (
    !DefaultElement &&
    (user?.user_type?.patient_access ||
      user?.user_type?.login_patient_access ||
      user?.user_type?.update_wallet_access)
  ) {
    DefaultElement = Patients;
    defaultRouteString = '/patients';
  }

  if (!DefaultElement && (user?.user_type?.doctor_access || user?.user_type?.login_doctor_access)) {
    DefaultElement = Doctors;
    defaultRouteString = '/doctors';
  }

  if (
    !DefaultElement &&
    (user?.user_type?.consultation_access ||
      user?.user_type?.reset_consultation_access ||
      user?.user_type?.generate_prescription_access ||
      user?.user_type?.reschedule_access ||
      user?.user_type?.did_not_attend_access ||
      user?.user_type?.cancel_access ||
      user?.user_type?.consultation_logs_access)
  ) {
    DefaultElement = Consultations;
    defaultRouteString = '/consultations';
  }

  if (!DefaultElement && user?.user_type?.product_access) {
    DefaultElement = Products;
    defaultRouteString = '/products';
  }

  if (
    !DefaultElement &&
    (user?.user_type?.prescription_nova_access || user?.user_type?.prescription_air_access)
  ) {
    DefaultElement = Orders;
    defaultRouteString = '/orders';
  }

  if (!DefaultElement && user?.user_type?.prescription_access) {
    DefaultElement = Prescriptions;
    defaultRouteString = '/prescriptions';
  }

  if (!DefaultElement && user?.user_type?.coupon_access) {
    DefaultElement = Coupons;
    defaultRouteString = '/coupons';
  }

  // @TODO - investigate
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (user && !user.is_super_admin && !user.user_type) {
      setOpen(true);
      logout();
    }
  }, [logout, user]);

  // console.log(DefaultElement, defaultRouteString);

  return (
    <>
      <Suspense fallback={null}>
        <ScrollToTop>
          <Routes>
            {isLoading ? (
              <Route
                path="*"
                element={
                  <div className="flex justify-center items-center fixed h-full w-full">
                    <LoaderSpinner className="w-10 h-20" />
                  </div>
                }
              />
            ) : (
              <>
                {isLoggedIn && user && DefaultElement && (
                  <Route path="/" element={<AuthLayout />}>
                    <Route path="*" element={<Navigate to={defaultRouteString || '/'} />} />
                    <Route index element={<DefaultElement />} />

                    {(user.is_super_admin ||
                      user.user_type?.patient_access ||
                      user.user_type?.login_patient_access) && (
                      <>
                        <Route path="patients" element={<Patients />} />
                        <Route
                          path="patients/:patientId/checkout-success"
                          element={<StripeCheckoutSuccess />}
                        />
                        <Route
                          path="patients/:patientId/cancel"
                          element={<StripeCheckoutCancel />}
                        />
                      </>
                    )}

                    {/* {(user.is_super_admin ||
                      user.user_type?.patient_access ||
                      user.user_type?.consultation_access) && (
                      <Route path="patient-profile/:id" element={<PatientProfile />} />
                    )} */}

                    {(user.is_super_admin ||
                      user.user_type?.doctor_access ||
                      user.user_type?.login_doctor_access) && (
                      <Route path="doctors" element={<Doctors />} />
                    )}

                    {(user.is_super_admin ||
                      user.user_type?.consultation_access ||
                      user?.user_type?.reset_consultation_access ||
                      user?.user_type?.generate_prescription_access ||
                      user?.user_type?.reschedule_access ||
                      user?.user_type?.did_not_attend_access ||
                      user?.user_type?.cancel_access ||
                      // @TODO
                      user?.user_type?.consultation_logs_access) && (
                      <Route path="consultations" element={<Consultations />} />
                    )}

                    {(user.is_super_admin || user.user_type?.product_access) && (
                      <Route path="products">
                        <Route index element={<Products />} />
                        <Route path="management" element={<ProductManagement />} />
                      </Route>
                    )}

                    {(user.is_super_admin ||
                      user.user_type?.prescription_nova_access ||
                      user.user_type?.prescription_air_access) && (
                      <Route path="orders" element={<Orders />} />
                    )}

                    {(user.is_super_admin || user?.user_type?.prescription_access) && (
                      <Route path="prescriptions" element={<Prescriptions />} />
                    )}

                    {(user.is_super_admin || user.user_type?.coupon_access) && (
                      <Route path="coupons" element={<Coupons />} />
                    )}

                    {user.is_super_admin && (
                      <>
                        <Route path="users" element={<AdminUsers />} />
                        <Route path="user-types" element={<AdminUserTypes />} />
                        <Route path="payment-transactions" element={<PaymentTransactions />} />
                      </>
                    )}
                  </Route>
                )}

                {!isLoggedIn && !user && (
                  <Route path="/">
                    <Route path="*" element={<Navigate to="/login" />} />
                    <Route index element={<Login />} />
                    <Route path="login" element={<Login />} />
                  </Route>
                )}
              </>
            )}
          </Routes>
        </ScrollToTop>
      </Suspense>

      <Modal open={open} onClose={setOpen} round="2xl">
        <ModalMessage
          message="Your user type is not configured, please contact your administrator"
          close={setOpen}
          variant="error"
        />
      </Modal>
    </>
  );
};

export default Router;
