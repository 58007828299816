import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import {
  IMenuActions,
  IMenuButton,
  IMenuLink,
  IMenuAtag,
  IMenuTypes,
  isIMenuAtag,
  isIMenuButton,
  isIMenuLink,
  isMenuActions
} from 'utils/models';

// components
import Button from './Button';
import MenuAction from './MenuAction';

interface ISideActions {
  actions: IMenuTypes[];
  isCollapse?: boolean;
  onMouseEnter?: (e: MouseEvent) => void;
  onMouseLeave?: (e: MouseEvent) => void;
}

const SideActions: FC<ISideActions> = ({ actions, isCollapse = false }) => {
  return (
    <>
      {/**
       * for side nav links
       */}
      {actions
        .filter((item: IMenuTypes) => !item.setting)
        .map((item: IMenuTypes, index: number) => (
          <div key={index}>
            {isIMenuLink(item) && <NavLink item={item} isCollapse={isCollapse} />}

            {isIMenuAtag(item) && <HrefNav item={item} isCollapse={isCollapse} />}

            {isIMenuButton(item) && <NavButton item={item} isCollapse={isCollapse} />}

            {isMenuActions(item) && <MenuActionItems item={item} isCollapse={isCollapse} />}

            {item.divider && <div className="my-5 border border-gray-100" />}
          </div>
        ))}

      <div className="absolute bottom-0 w-full">
        {actions
          .filter((item: IMenuTypes) => item.setting)
          .map((item: IMenuTypes, index: number) => (
            <div key={index}>
              {isIMenuLink(item) && <NavLink item={item} isCollapse={isCollapse} />}

              {isIMenuAtag(item) && <HrefNav item={item} isCollapse={isCollapse} />}

              {isIMenuButton(item) && <NavButton item={item} isCollapse={isCollapse} />}

              {isMenuActions(item) && <MenuActionItems item={item} isCollapse={isCollapse} />}

              {item.divider && <div className="my-5 border border-gray-100" />}
            </div>
          ))}
      </div>
    </>
  );
};

// MENU ITEMS this is the "settings"
interface IMenuActionItems {
  item: IMenuActions;
  isCollapse?: boolean;
}

const MenuActionItems: FC<IMenuActionItems> = ({ item, isCollapse = false }) => {
  const [isHovered, setHovered] = useState(false);
  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  return (
    <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <MenuAction
        placement="auto"
        classNameMain="w-full "
        menuButton={
          <Button
            variant="invisible"
            size="inline"
            rounded={item?.itemRound || 'none'}
            className={clsx(
              // !isCollapse && 'space-x-6',
              'p-3 m-1.5 flex items-center border-transparent text-polln-black-400',
              'hover:bg-white hover:rounded-full'
            )}
          >
            {item.icon && <div className={clsx('text-polln-solid-green-500')}>{item.icon}</div>}
            {/* {!isCollapse && (
            <span className={clsx('text-sm text-polln-solid-green-500 font-semibold')}>
              {item.label}
            </span>
          )} */}
            {isHovered && isCollapse && (
              <div
                className={clsx(
                  'absolute left-full top-1/2 transform',
                  '-translate-y-1/2 px-3 py-1 bg-white rounded-full bg-opacity-70 text-opacity-70',
                  'text-black text-xxs font-semibold'
                )}
              >
                {item.label}
              </div>
            )}
          </Button>
        }
        menu={item.actions}
      />
    </div>
  );
};

// NAV LINK FOR LINK ROUTES
interface INavLink {
  item: IMenuLink;
  isCollapse?: boolean;
}

const NavLink: FC<INavLink> = ({ item, isCollapse = false }) => {
  const location = useLocation();
  const active = location.pathname.indexOf(item.to as string) === 0;

  const [isHovered, setHovered] = useState(false);
  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  return (
    <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Link
        to={item.to}
        className={clsx(
          active && 'rounded-full bg-red-500dashicon pointer-events-none bg-polln-green-300',
          // !isCollapse && 'space-x-6',
          'p-3 m-1.5 flex items-center border-transparent text-polln-black-400',
          'hover:bg-white hover:rounded-full w-max'
        )}
      >
        {item.icon && <div className={clsx('text-polln-solid-green-500')}>{item.icon}</div>}
        {/* {!isCollapse && (
        <span
          className={clsx(
            'text-sm',
            active ? 'text-white font-semibold' : 'text-polln-solid-green-500 font-semibold'
          )}
        >
          {item.label}
        </span>
)} */}
        {isHovered && isCollapse && (
          <div
            className={clsx(
              'z-[1000] absolute left-full top-1/2 transform',
              '-translate-y-1/2 px-3 py-1 bg-white rounded-full bg-opacity-70 text-opacity-70',
              'text-black text-xxs font-semibold'
            )}
          >
            {item.label}
          </div>
        )}
      </Link>
    </div>
  );
};

// HREF FOR ANCHOR TAGS <a href...>
interface IHrefNav {
  item: IMenuAtag;
  isCollapse?: boolean;
}

const HrefNav: FC<IHrefNav> = ({ item, isCollapse = false }) => {
  const [isHovered, setHovered] = useState(false);
  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);
  return (
    <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <a
        href={item.href}
        className={clsx(
          'w-full',
          'flex items-center border-transparent text-polln-black-400',
          'hover:bg-white hover:shadow-md',
          !isCollapse && 'space-x-6',
          'py-5 px-8'
        )}
        target={item.target}
        rel="noreferrer"
      >
        {item.icon && <div className={clsx('text-polln-solid-green-500')}>{item.icon}</div>}
        {/* {!isCollapse && (
        <span className={clsx('text-sm text-polln-solid-green-500 font-semibold')}>
          {item.label}
        </span>
      )} */}
        {isHovered && isCollapse && (
          <div
            className={clsx(
              'z-10 absolute left-full top-1/2 transform',
              '-translate-y-1/2 px-3 py-1 bg-white rounded-full bg-opacity-70 text-opacity-70',
              'text-black text-xxs font-semibold w-[165px]'
            )}
          >
            {item.label}
          </div>
        )}
      </a>
    </div>
  );
};

// NAV BUTTON FOR BUTTONS
interface INavButton {
  item: IMenuButton;
  isCollapse?: boolean;
}

const NavButton: FC<INavButton> = ({ item, isCollapse = false }) => {
  const [isHovered, setHovered] = useState(false);
  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);
  return (
    <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Button
        variant="invisible"
        type={item.type}
        onClick={item.onClick}
        size="inline"
        rounded={item?.itemRound || 'none'}
        className={clsx(
          'w-full',
          'flex items-center border-transparent text-polln-black-400',
          'hover:bg-white'
        )}
        classNameButton={clsx(!isCollapse && 'space-x-6', 'py-5 px-8 ')}
      >
        {item.icon && <div className={clsx('text-polln-solid-green-500')}>{item.icon}</div>}
        {/* {!isCollapse && (
        <span className={clsx('text-sm text-polln-solid-green-500 font-semibold')}>
          {item.label}
        </span>
      )} */}

        {isHovered && isCollapse && (
          <div
            className={clsx(
              'z-10 absolute left-full top-1/2 transform',
              '-translate-y-1/2 px-3 py-1 bg-white rounded-full bg-opacity-70 text-opacity-70',
              'text-black text-xxs font-semibold w-[165px] text-center'
            )}
          >
            {item.label}
          </div>
        )}
      </Button>
    </div>
  );
};

export default SideActions;
